// import local dependencies
import '@css/main.scss';
import '@js/bootstrap';
import $ from 'jquery';
import jQuery from 'jquery';


import Router from './util/Router';
import common from './routes/common';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
    common,
});

jQuery(document).ready(() => routes.loadEvents());
