
export class Header {

    constructor()
    {
        this.init()
    }

    init()
    {
        this.registerElements()
        if (window.device === 'desktop') {
            this.registerIntersectionObserver()
        }
        this.initInitialColor()
        this.scrollHandler()

    }

    registerIntersectionObserver()
    {
        let observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (this.firstTriggers !== 0) {
                    this.firstTriggers -= 1
                    return;
                }

                let section = entry.target
                let sectionColor = window.getComputedStyle(section, null).getPropertyValue('background-color'); // get background color of section
                this.switchHeaderColors(sectionColor)

            })
        }, this.options)

        this.elements.forEach((element) => {
            observer.observe(element);
        })
    }

    registerElements()
    {
        this.colors = {
            'default': '',
            'rgb(255, 255, 255)': 'dark-header',
            'rgb(1, 21, 56)': 'light-header',

        }

        this.header = document.querySelector('header')
        this.elements = document.querySelectorAll('section')
        this.firstTriggers = this.elements.length

        let pageHeight = window.innerHeight;
        let headerHeight = this.header.offsetHeight;

        this.options = {
            root: document.body,
            rootMargin: `${headerHeight * -1}px 0px ${(pageHeight * -1) + headerHeight }px 0px`,
            threshold: 0,
        }

    }

    switchHeaderColors(color)
    {
        console.log(color, Object.keys(this.colors))
        let _color = (Object.keys(this.colors).includes(color)) ? this.colors[color] : this.colors['default']
        Object.values(this.colors).forEach((value) => {
            if (value !== '') {
                this.header.classList.remove(value)
            }
        })
        if (_color !== '') {
            this.header.classList.add(_color)
        }
    }

    initInitialColor()
    {
        let firstSection = this.elements[0]
        let sectionColor = window.getComputedStyle(firstSection, null).getPropertyValue('background-color');
        this.switchHeaderColors(sectionColor)

    }

    scrollHandler()
    {

        if (document.documentElement.scrollTop || document.body.scrollTop > 0) {
            this.header.classList.add('scrolled')
        }

        document.addEventListener('scroll', () => {
            let height = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;

            if (height > 0 && !this.header.classList.contains('scrolled')) {
                this.header.classList.toggle('scrolled')
            }
            if (height === 0 && this.header.classList.contains('scrolled')) {
                this.header.classList.toggle('scrolled')
            }
        })

    }

}
