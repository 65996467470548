import $ from 'jquery'
export class PdfForm {

  isPdfFormActive() {
    return this.pdfForm.classList.contains('active');
  }

  constructor() {
    this.pdfElements = document.querySelectorAll('[dl-form]');
    console.log(this.pdfElements);
    if (this.pdfElements.length === 0) {
      return;
    }

    this.init()
  }

  init() {
    this.pdfForm = document.getElementById('pdf-form');
    if (!this.pdfForm) {
      return;
    }

    let closeButton = this.pdfForm.querySelector('#close');
    closeButton.addEventListener('click', () => {
      this.closePdfForm();
    })

    this.pdfElements.forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        this.openPdfForm(el);
      })
    })

    let overlay = document.querySelector('#pdf-form-overlay')
    document.addEventListener('click', (e) => {
      if (e.target === overlay) {
        this.closePdfForm();
      }
    })

  }

  openPdfForm(el) {
    let i = 0
    this.pdfForm.classList.add('active');
    let pdfLink = el.getAttribute('dl-form-link');
    let form = document.querySelector('.forminator-ui')
    console.log(form)
    form.setAttribute('data-link', pdfLink)
    let that = this
    $(document).on('forminator:form:submit:success', function () {
      i++;

      if (i <= 1) {
        window.open(form.getAttribute('data-link'), '_blank');
        console.log(form.getAttribute('data-link'))

      }
      that.closePdfForm();
    });
  }

  closePdfForm() {
    this.pdfForm.classList.remove('active');
  }

}
