import $ from 'jquery'

export class Slider {

    constructor(Swiper, modules)
    {
        this.init()
        Object.keys(this.sliders).forEach(slider => {
            let _slider = document.querySelector('.' + slider)
            if (!_slider) {
                return;
            }
            this.sliders[slider](Swiper, modules, _slider)
        })
    }

    init() // register sliders here
    {
        this.sliders = { // className: function
            'testimonial-slider': this.testimonialSlider,
            'partner-slider': this.partnerSlider,
            'certifications-slider': this.certificationSlider,
            'logiciels-right': this.logicielsRight,
            'logiciels-left': this.logicielsLeft,
            'whoareyou-slider': this.whoareyou,
            'video-slider': this.videoslider,
        }
    }

    testimonialSlider(Swiper, modules, slider)
    {
        new Swiper(slider, {
            modules: modules,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            slidesPerView: 1,
            autoplay: {
                delay: 5000,
            },
            loop: true,
        });
    }

    whoareyou(Swiper, modules, slider)
    {
        new Swiper(slider, {
            modules: modules,
            spaceBetween: 50,
            autoplay: {
                delay: 5000,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            loop: true,
            speed: 1000,
            breakpoints: {
                100: {
                    slidesPerView: 1,
                },
                1050: {
                    slidesPerView: 2,
                },
            },
        });
    }

  videoslider(Swiper, modules, slider)
  {
    new Swiper(slider, {
      modules: modules,
      spaceBetween: 0,
      loopFillGroupWithBlank: false,
      slidesPerGroup: 3,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      speed: 1000,
      breakpoints: {
        100: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        769: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        1200: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
      },
    });
  }

    logicielsRight(Swiper, modules, slider)
    {
        new Swiper(slider, {
            modules: modules,
            slidesPerView: 3,
            slidesPerGroup: 3,
            loopFillGroupWithBlank: false,
            autoplay: {
                delay: 5000,
            },
            loop: true,
            speed: 1000,
            breakpoints: {
                100: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
                // when window width is 100px <= width <= 450
                750: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                // when window width is 450 <= width <= 1200px
                1200: {
                    slidesPerView: 5,
                    slidesPerGroup: 5,
                },
            },
        });
    }

    logicielsLeft(Swiper, modules, slider)
    {
        new Swiper(slider, {
            modules: modules,
            slidesPerView: 3,
            slidesPerGroup: 3,
            loopFillGroupWithBlank: false,
            autoplay: {
                delay: 5000,
            },
            loop: true,
            speed: 1000,
            breakpoints: {
                100: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
                // when window width is 100px <= width <= 450
                750: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                // when window width is 450 <= width <= 1200px
                1200: {
                    slidesPerView: 5,
                    slidesPerGroup: 5,
                },
            },
        });
    }



    partnerSlider(Swiper, modules, slider)
    {
        new Swiper(slider, {
            modules: modules,
            loopFillGroupWithBlank: false,
            autoplay: {
                delay: 5000,
            },
            loop: true,
            speed: 1000,
            breakpoints: {
                100: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
                // when window width is >= 320px
                450: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                // when window width is >= 480px
                750: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                // when window width is >= 640px
                1200: {
                    slidesPerView: 5,
                    slidesPerGroup: 5,
                },
            },

        });
    }

    certificationSlider(Swiper, modules, slider)
    {
        new Swiper(slider, {
            modules: modules,
            slidesPerView: 3,
            slidesPerGroup: 3,
            loopFillGroupWithBlank: false,
            autoplay: {
                delay: 5000,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            loop: true,
            speed: 1000,
            breakpoints: {
                100: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
                // when window width is 100px <= width <= 450
                750: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                // when window width is 450 <= width <= 1200px
                1200: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
            },
        });
    }

}
